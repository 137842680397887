export const domains = {
  "CalcLoom.org": ["#32CD32", "#FF6347", "#6B8E23"],
  "MyTaskTrack.io": ["#6A5ACD", "#FF4500", "#48D1CC"],
  "ChatWave.io": ["#8A2BE2", "#FFA500", "#3CB371"],
  "ChatCraft.app": ["#6A5ACD", "#FFD700", "#32CD32"],
  "SwiftNotes.co": ["#6A5ACD", "#FF6347", "#32CD32"],
  "TaskJot.com": ["#008080", "#FF6347", "#4682B4"],
  "EasyNotes.co": ["#2E8B57", "#FFA07A", "#4682B4"],
  "SwiftConvert.co": ["#6A5ACD", "#FF69B4", "#3CB371"],
  "CalcGuru.com": ["#FF4500", "#FFD700", "#8A2BE2"],
  "ChatPro.com": ["#5F9EA0", "#FF69B4", "#008080"],
  "SmartFlash.co": ["#6B8E23", "#FFD700", "#1E90FF"],
  "NoteStream.org": ["#3CB371", "#FF6347", "#87CEFA"],
  "PomodoroFlow.net": ["#8FBC8F", "#FF1493", "#20B2AA"],
  "NoteNexus.org": ["#32CD32", "#FF69B4", "#00CED1"],
  "RecipePulse.org": ["#20B2AA", "#FF4500", "#6A5ACD"],
  "SwiftFlash.co": ["#6A5ACD", "#FF6347", "#32CD32"],
  "PomodoroNest.app": ["#556B2F", "#FF1493", "#48D1CC"],
  "EasyChat.co": ["#2E8B57", "#FFA07A", "#4682B4"],
  "SwiftTasks.co": ["#32CD32", "#FF6347", "#00CED1"],
  "TaskLoom.app": ["#8FBC8F", "#FF6347", "#00CED1"],
  "PomodoroSwift.com": ["#8FBC8F", "#FFA07A", "#20B2AA"],
  "RecipePath.co": ["#5F9EA0", "#FFA500", "#008080"],
  "QuickRecipes.net": ["#8A2BE2", "#FF6347", "#3CB371"],
  "WeatherNest.io": ["#5F9EA0", "#FFA500", "#4682B4"],
  "MyQuizHub.io": ["#3CB371", "#FF1493", "#87CEFA"],
  "RecipePro.com": ["#5F9EA0", "#FF69B4", "#008080"],
  "RecipeCraft.app": ["#6A5ACD", "#FFD700", "#32CD32"],
  "FlashMagic.app": ["#32CD32", "#FFD700", "#00CED1"],
  "CalcPulse.net": ["#48D1CC", "#FF1493", "#6B8E23"],
  "WeatherNexus.org": ["#6A5ACD", "#FFD700", "#32CD32"],
  "SkyMingle.net": ["#3CB371", "#FF6347", "#87CEFA"],
  "QuickQuiz.net": ["#8A2BE2", "#FF6347", "#3CB371"],
  "PomodoroNexus.io": ["#2E8B57", "#FF6347", "#4682B4"],
  "ChatMagic.app": ["#32CD32", "#FFD700", "#00CED1"],
  "MyWeatherHub.io": ["#87CEFA", "#FFD700", "#20B2AA"],
  "WeatherCraft.app": ["#3CB371", "#FF1493", "#87CEFA"],
  "QuizFlow.net": ["#8FBC8F", "#FF1493", "#20B2AA"],
  "TaskBlitz.io": ["#556B2F", "#FF4500", "#87CEFA"],
  "QuizNexus.org": ["#32CD32", "#FF69B4", "#00CED1"],
  "QuickPomodoro.net": ["#8A2BE2", "#FF6347", "#3CB371"],
  "ChatMingle.net": ["#6A5ACD", "#FF4500", "#32CD32"],
  "RecipeNexus.org": ["#32CD32", "#FF69B4", "#00CED1"],
  "CalcNest.com": ["#00BFFF", "#FF4500", "#8FBC8F"],
  "QuizWave.co": ["#3CB371", "#FFD700", "#87CEFA"],
  "SmartPomodoro.co": ["#6B8E23", "#FFD700", "#1E90FF"],
  "NotePulse.org": ["#20B2AA", "#FF4500", "#6A5ACD"],
  "CalcWave.io": ["#8A2BE2", "#FF69B4", "#20B2AA"],
  "WeatherNest.com": ["#6A5ACD", "#FF6347", "#32CD32"],
  "ChatNest.app": ["#556B2F", "#FF1493", "#48D1CC"],
  "EasyPomodoro.co": ["#2E8B57", "#FFA07A", "#4682B4"],
  "CalcNexus.org": ["#87CEFA", "#FF4500", "#5F9EA0"],
  "RecipeMagic.app": ["#32CD32", "#FFD700", "#00CED1"],
  "QuizPath.co": ["#5F9EA0", "#FFA500", "#008080"],
  "QuickConvert.net": ["#5F9EA0", "#FF6347", "#8FBC8F"],
  "MyChatHub.io": ["#3CB371", "#FF1493", "#87CEFA"],
  "EasyFlash.co": ["#2E8B57", "#FFA07A", "#4682B4"],
  "ChatPath.co": ["#5F9EA0", "#FFA500", "#008080"],
  "TaskBreeze.net": ["#6B8E23", "#FF4500", "#4682B4"],
  "SkySwift.com": ["#556B2F", "#FFD700", "#48D1CC"],
  "QuickWeather.co": ["#8FBC8F", "#FF69B4", "#20B2AA"],
  "FlashNest.com": ["#32CD32", "#FF4500", "#00CED1"],
  "CalcNest.app": ["#48D1CC", "#FFD700", "#556B2F"],
  "RecipeNest.com": ["#32CD32", "#FF4500", "#00CED1"],
  "FlashNexus.io": ["#2E8B57", "#FF6347", "#4682B4"],
  "CalcPath.io": ["#1E90FF", "#FFA500", "#3CB371"],
  "WeatherNest.app": ["#6B8E23", "#FF6347", "#1E90FF"],
  "TaskMingle.net": ["#32CD32", "#FF69B4", "#4682B4"],
  "WeatherNexus.io": ["#8FBC8F", "#FF1493", "#20B2AA"],
  "FlashSwift.com": ["#8FBC8F", "#FFA07A", "#20B2AA"],
  "QuizNexus.io": ["#2E8B57", "#FF6347", "#4682B4"],
  "SkyHive.io": ["#32CD32", "#FFD700", "#00CED1"],
  "QuizSwift.com": ["#8FBC8F", "#FFA07A", "#20B2AA"],
  "WeatherPath.co": ["#20B2AA", "#FFA500", "#6A5ACD"],
  "SkyLoom.org": ["#8A2BE2", "#FF69B4", "#3CB371"],
  "FlashHive.io": ["#20B2AA", "#FF69B4", "#6A5ACD"],
  "SkyWave.co": ["#2E8B57", "#FFA07A", "#4682B4"],
  "PomodoroWave.co": ["#3CB371", "#FFD700", "#87CEFA"],
  "CalcSwift.com": ["#00CED1", "#FF1493", "#2E8B57"],
  "MyCalcHub.io": ["#00CED1", "#FF1493", "#32CD32"],
  "ConvertCraft.app": ["#6A5ACD", "#FF6347", "#32CD32"],
  "QuickChat.net": ["#8A2BE2", "#FF6347", "#3CB371"],
  "RecipeStream.org": ["#3CB371", "#FF6347", "#87CEFA"],
  "SkyTrack.net": ["#00BFFF", "#FF6347", "#32CD32"],
  "NotePro.com": ["#5F9EA0", "#FF69B4", "#008080"],
  "NoteFlow.net": ["#8FBC8F", "#FF1493", "#20B2AA"],
  "QuizLoom.org": ["#6B8E23", "#FFD700", "#1E90FF"],
  "SmartQuiz.co": ["#6B8E23", "#FFD700", "#1E90FF"],
  "TaskWave.co": ["#6A5ACD", "#FFA500", "#87CEFA"],
  "PomodoroCraft.app": ["#6A5ACD", "#FFD700", "#32CD32"],
  "RecipeHive.io": ["#20B2AA", "#FF69B4", "#6A5ACD"],
  "SmartCalc.co": ["#6A5ACD", "#FF4500", "#5F9EA0"],
  "EasyCalc.co": ["#00CED1", "#FFA500", "#556B2F"],
  "CalcBlitz.org": ["#00BFFF", "#FF6347", "#3CB371"],
  "EasyQuiz.co": ["#2E8B57", "#FFA07A", "#4682B4"],
  "CalcWave.co": ["#4682B4", "#FF6347", "#8FBC8F"],
  "CalcFlow.co": ["#8A2BE2", "#FF1493", "#5F9EA0"],
  "ChatPulse.org": ["#20B2AA", "#FF4500", "#6A5ACD"],
  "QuizPro.com": ["#5F9EA0", "#FF69B4", "#008080"],
  "QuizStream.org": ["#3CB371", "#FF6347", "#87CEFA"],
  "ListMaster.app": ["#8FBC8F", "#FF1493", "#1E90FF"],
  "TodoZen.co": ["#6B8E23", "#FFA500", "#00BFFF"],
  "TaskCraft.co": ["#008080", "#FF1493", "#87CEFA"],
  "NoteNest.com": ["#32CD32", "#FF4500", "#00CED1"],
  "TaskSwift.com": ["#2E8B57", "#FFA07A", "#48D1CC"],
  "TaskFlow.app": ["#32CD32", "#FF4500", "#00CED1"],
  "PomodoroPulse.org": ["#20B2AA", "#FF4500", "#6A5ACD"],
  "QuizMagic.app": ["#32CD32", "#FFD700", "#00CED1"],
  "TodoPath.io": ["#3CB371", "#FF1493", "#00BFFF"],
  "SmartRecipes.co": ["#6B8E23", "#FFD700", "#1E90FF"],
  "SwiftRecipes.co": ["#6A5ACD", "#FF6347", "#32CD32"],
  "TodoStream.org": ["#556B2F", "#FFD700", "#1E90FF"],
  "NoteMingle.net": ["#6A5ACD", "#FF4500", "#32CD32"],
  "RecipeWave.co": ["#3CB371", "#FFD700", "#87CEFA"],
  "SwiftQuiz.co": ["#6A5ACD", "#FF6347", "#32CD32"],
  "PomodoroMagic.app": ["#32CD32", "#FFD700", "#00CED1"],
  "CalcCraft.app": ["#4682B4", "#FF69B4", "#3CB371"],
  "PomodoroHive.io": ["#20B2AA", "#FF69B4", "#6A5ACD"],
  "DoItList.net": ["#3CB371", "#FFA07A", "#4682B4"],
  "RecipeNest.app": ["#556B2F", "#FF1493", "#48D1CC"],
  "WeatherBreeze.net": ["#5F9EA0", "#FF4500", "#008080"],
  "NoteLoom.org": ["#6B8E23", "#FFD700", "#1E90FF"],
  "WeatherFlow.net": ["#556B2F", "#FF4500", "#48D1CC"],
  "RecipeLoom.org": ["#6B8E23", "#FFD700", "#1E90FF"],
  "QuizWave.io": ["#8A2BE2", "#FFA500", "#3CB371"],
  "ChatHive.io": ["#20B2AA", "#FF69B4", "#6A5ACD"],
  "QuizPulse.org": ["#20B2AA", "#FF4500", "#6A5ACD"],
  "MyRecipeHub.io": ["#3CB371", "#FF1493", "#87CEFA"],
  "QuizCraft.app": ["#6A5ACD", "#FFD700", "#32CD32"],
  "TodoMagic.org": ["#5F9EA0", "#FF69B4", "#8A2BE2"],
  "PomodoroLoom.org": ["#6B8E23", "#FFD700", "#1E90FF"],
  "FlashPro.com": ["#5F9EA0", "#FF69B4", "#008080"],
  "CalcMagic.app": ["#4682B4", "#FFD700", "#2E8B57"],
  "FlashWave.io": ["#8A2BE2", "#FFA500", "#3CB371"],
  "QuickTasks.co": ["#3CB371", "#FFD700", "#1E90FF"],
  "SmartChat.co": ["#6B8E23", "#FFD700", "#1E90FF"],
  "QuickNotes.net": ["#8A2BE2", "#FF6347", "#3CB371"],
  "SwiftPomodoro.co": ["#6A5ACD", "#FF6347", "#32CD32"],
  "FlashLoom.org": ["#6B8E23", "#FFD700", "#1E90FF"],
  "NoteHive.io": ["#20B2AA", "#FF69B4", "#6A5ACD"],
  "CalcHive.io": ["#87CEFA", "#FFD700", "#20B2AA"],
  "NoteMagic.app": ["#32CD32", "#FFD700", "#00CED1"],
  "ChatLoom.org": ["#6B8E23", "#FFD700", "#1E90FF"],
  "ChatNest.com": ["#32CD32", "#FF4500", "#00CED1"],
  "QuizMingle.net": ["#6A5ACD", "#FF4500", "#32CD32"],
  "QuizHive.io": ["#20B2AA", "#FF69B4", "#6A5ACD"],
  "SwiftCalc.co": ["#1E90FF", "#FFA500", "#556B2F"],
  "PomodoroWave.io": ["#8A2BE2", "#FFA500", "#3CB371"],
  "NoteNest.app": ["#556B2F", "#FF1493", "#48D1CC"],
  "FlashMingle.net": ["#6A5ACD", "#FF4500", "#32CD32"],
  "WeatherPro.com": ["#20B2AA", "#FF1493", "#00CED1"],
  "FlashNexus.org": ["#32CD32", "#FF69B4", "#00CED1"],
  "RecipeWave.io": ["#8A2BE2", "#FFA500", "#3CB371"],
  "SkyNest.app": ["#6B8E23", "#FFD700", "#1E90FF"],
  "RecipeFlow.net": ["#8FBC8F", "#FF1493", "#20B2AA"],
  "NoteSwift.com": ["#8FBC8F", "#FFA07A", "#20B2AA"],
  "FlashNest.app": ["#556B2F", "#FF1493", "#48D1CC"],
  "CalcStream.org": ["#1E90FF", "#FF6347", "#2E8B57"],
  "QuizNest.com": ["#32CD32", "#FF4500", "#00CED1"],
  "EasyRecipes.co": ["#2E8B57", "#FFA07A", "#4682B4"],
  "TodoHive.io": ["#5F9EA0", "#FFA500", "#00BFFF"],
  "NoteCraft.app": ["#6A5ACD", "#FFD700", "#32CD32"],
  "PomodoroStream.org": ["#3CB371", "#FF6347", "#87CEFA"],
  "SmartNotes.co": ["#6B8E23", "#FF6347", "#1E90FF"],
  "PomodoroPro.com": ["#5F9EA0", "#FF69B4", "#008080"],
  "TaskPulse.net": ["#2E8B57", "#FFA07A", "#4682B4"],
  "FlashPath.co": ["#5F9EA0", "#FFA500", "#008080"],
  "ConvertNest.com": ["#32CD32", "#FFD700", "#00CED1"],
  "ConvertNest.app": ["#556B2F", "#FF1493", "#48D1CC"],
  "NoteWave.io": ["#8A2BE2", "#FFA500", "#3CB371"],
  "MyFlashHub.io": ["#3CB371", "#FF1493", "#87CEFA"],
  "PomodoroPath.co": ["#5F9EA0", "#FFA500", "#008080"],
  "WeatherMagic.app": ["#6A5ACD", "#FF4500", "#2E8B57"],
  "ChatStream.org": ["#3CB371", "#FF6347", "#87CEFA"],
  "FlashFlow.net": ["#8FBC8F", "#FF1493", "#20B2AA"],
  "WeatherWave.com": ["#4682B4", "#FFA07A", "#8FBC8F"],
  "WeatherZen.co": ["#008080", "#FF6347", "#8FBC8F"],
  "TaskWave.app": ["#20B2AA", "#FFD700", "#DC143C"],
  "RecipeSwift.com": ["#8FBC8F", "#FFA07A", "#20B2AA"],
  "TodoNexus.org": ["#20B2AA", "#FF6347", "#1E90FF"],
  "NotePath.co": ["#5F9EA0", "#FFA500", "#008080"],
  "FlashCraft.app": ["#6A5ACD", "#FFD700", "#32CD32"],
  "SwiftChat.co": ["#6A5ACD", "#FF6347", "#32CD32"],
  "MyPomodoroHub.io": ["#3CB371", "#FF1493", "#87CEFA"],
  "WeatherPulse.org": ["#32CD32", "#FFD700", "#3CB371"],
  "QuickFlash.net": ["#8A2BE2", "#FF6347", "#3CB371"],
  "QuizNest.app": ["#556B2F", "#FF1493", "#48D1CC"],
  "CalcPro.com": ["#87CEFA", "#FFA07A", "#8FBC8F"],
  "ConvertWave.co": ["#3CB371", "#FFD700", "#87CEFA"],
  "ChatNexus.io": ["#2E8B57", "#FF6347", "#4682B4"],
  "CalcMingle.net": ["#00BFFF", "#FF69B4", "#20B2AA"],
  "PomodoroMingle.net": ["#6A5ACD", "#FF4500", "#32CD32"],
  "ChatFlow.net": ["#8FBC8F", "#FF1493", "#20B2AA"],
  "TodoPulse.org": ["#6A5ACD", "#FF6347", "#48D1CC"],
  "ChatWave.co": ["#3CB371", "#FFD700", "#87CEFA"],
  "NoteNexus.io": ["#2E8B57", "#FF6347", "#4682B4"],
  "MyNoteHub.io": ["#3CB371", "#FF1493", "#87CEFA"],
  "NoteWave.co": ["#3CB371", "#FFD700", "#87CEFA"],
  "FlashWave.co": ["#3CB371", "#FFD700", "#87CEFA"],
  "PomodoroNexus.org": ["#32CD32", "#FF69B4", "#00CED1"],
  "PomodoroNest.com": ["#32CD32", "#FF4500", "#00CED1"],
  "FlashPulse.org": ["#20B2AA", "#FF4500", "#6A5ACD"],
  "TaskNest.com": ["#20B2AA", "#FF69B4", "#8A2BE2"],
  "RecipeNexus.io": ["#2E8B57", "#FF6347", "#4682B4"],
  "ChatNexus.org": ["#32CD32", "#FF69B4", "#00CED1"],
  "TaskTrekker.com": ["#2E8B57", "#FFD700", "#1E90FF"],
  "ConvertPath.co": ["#5F9EA0", "#FFA500", "#008080"],
  "RecipeMingle.net": ["#6A5ACD", "#FF4500", "#32CD32"],
  "ChatSwift.com": ["#8FBC8F", "#FFA07A", "#20B2AA"],
  "FlashStream.org": ["#3CB371", "#FF6347", "#87CEFA"],
  "QuickCalc.net": ["#6A5ACD", "#FFA500", "#008080"],
  "SwiftWeather.co": ["#8A2BE2", "#FF69B4", "#556B2F"],
  "SkyStream.org": ["#2E8B57", "#FFA07A", "#4682B4"],
};
