import piranha from "./piranha.png";
import "./App.css";

import { useEffect, useState } from "react";
import { domains } from "./data";

function App() {
  const [currentMillis, setCurrentMillis] = useState(Date.now());

  const minutes = 24;
  const minutesInMs = minutes * 60000;
  const fullDurationsSinceEpoch = Math.floor(currentMillis / minutesInMs);
  const remainingMillis = currentMillis % minutesInMs;

  const remainingMinutes = minutes - Math.floor(remainingMillis / 60000) - 1;
  let remainingSeconds = 60 - Math.floor((remainingMillis % 60000) / 1000) - 1;

  if (remainingSeconds < 10) {
    remainingSeconds = `0${remainingSeconds}`;
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMillis(Date.now());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const domainNames = Object.keys(domains);
  const fullDomain = domainNames[fullDurationsSinceEpoch % domainNames.length];

  const theme = domains[fullDomain];
  const domain = fullDomain.toLowerCase();

  return (
    <div className="App">
      {/* make image appear */}
      <header className="App-header bg-black">
        <img
          src={piranha}
          className="  absolute animate-fadeIn max-h-[40%] top-0 pointer-events-none"
        />
        <div className="flex flex-col">
          <p
            className="relative text-xl p-4 md:text-6xl md:p-8 font-bold  border-2 rounded-lg animate-ping-border"
            style={{ "--border-color": theme[1] }}
          >
            {domain}
          </p>
          {/* display 3 hex color branding */}
          <div className="flex flex-row w-full mt-8">
            <span
              className={`flex flex-grow justify-center bg-[${theme[0]}] p-2 `}
              style={{ backgroundColor: theme[0] }}
            >
              {theme[0]}
            </span>
            <span
              className={`flex flex-grow justify-center bg-[${theme[1]}] p-2 `}
              style={{ backgroundColor: theme[1] }}
            >
              {theme[1]}
            </span>
            <span
              className={`flex flex-grow justify-center bg-[${theme[2]}] p-2 `}
              style={{ backgroundColor: theme[2] }}
            >
              {theme[2]}
            </span>
          </div>
          <div className="flex">
            <div className="flex flex-grow"></div>
            <a
              className="mt-12 hover:text-blue-300 text-2xl font-bold text-right"
              href={`https://www.namecheap.com/domains/registration/results/?domain=${domain}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              claim
            </a>
          </div>
        </div>
      </header>
      <div className="absolute bottom-0 right-0 p-4 text-gray-300 font-mono">
        <p>
          new domain in {remainingMinutes}:{remainingSeconds}
        </p>
      </div>
    </div>
  );
}

export default App;
